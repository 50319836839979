module.exports = {
  breakpoints: ['46.25em', '69.25em'],
  colors: {
    text: '#232323',
    background: '#D7DBDF',
    backgroundHeader: 'rgb(239, 242, 244)',
    bgmuted: '#b3c1ca',
    primary: '#000',
    secondary: '#926e5a',
    muted: '#999',
    clientbg: '#fff',
    // modes is necessary to enable the theme colors at the <body> level
    modes: {
      other: {},
    },
  },
  fonts: {
    body: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.3,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'body',
      lineHeight: 'heading',
      marginTop: 0,
      marginBottom: 3,
    },
    smallcaps: {
      textTransform: 'uppercase',
      fontSize: 0,
      m: 0,
      mb: 2,
    },
    navlink: {
      display: 'inline-block',
      textDecoration: 'none',
      fontSize: [0, 1],
      my: 1,
      color: 'primary',
      ':hover, :focus': {
        color: 'secondary',
      },
    },
  },
  sizes: {
    container: 1400,
  },
  forms: {
    select: {
      p: 1,
      pr: '2em',
      border: '1px solid #aaa',
      borderRadius: '.5em',
      boxShadow: '0 1px 0 1px rgba(0,0,0,.04)',
      backgroundImage: 'linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%)',
      backgroundRepeat: 'repeat',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      color: 'text',
      bg: 'background',
      fontSize: [1, 2],
    },
    Container: {
      p: 0,
      mb: 4,
      maxWidth: 1400,
    },
    // the keys used here reference elements in MDX
    h1: {
      variant: 'text.heading',
      fontSize: [4, 5],
    },
    h2: {
      variant: 'text.heading',
      fontSize: [3, 4],
      marginBottom: 2,
    },
    h3: {
      variant: 'text.heading',
      fontWeight: 'heading',
      marginBottom: 0,
    },
    p: {
      marginTop: 0,
      marginBottom: 3,
    },
    a: {
      color: 'primary',
      ':hover, :focus': {
        color: 'secondary',
      },
    },
  },
};
