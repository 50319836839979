// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-generic-content-js": () => import("./../../../src/templates/generic-content.js" /* webpackChunkName: "component---src-templates-generic-content-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-insight-js": () => import("./../../../src/templates/insight.js" /* webpackChunkName: "component---src-templates-insight-js" */),
  "component---src-templates-perspectives-js": () => import("./../../../src/templates/perspectives.js" /* webpackChunkName: "component---src-templates-perspectives-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

